import { render, staticRenderFns } from "./CaseCategory.vue?vue&type=template&id=016f0072&scoped=true&"
import script from "./CaseCategory.vue?vue&type=script&lang=js&"
export * from "./CaseCategory.vue?vue&type=script&lang=js&"
import style0 from "./CaseCategory.vue?vue&type=style&index=0&id=016f0072&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "016f0072",
  null
  
)

export default component.exports