<template>
	<div class="category">
        <div class="box">
            <!-- 区域、位置 -->
            <div v-if="showAddress" class="list-item" >
                <span class="item-title">{{ addressTitle }}：</span>
                <div class="item-content">
                    <div
                        class="item-tab"
                        :class="showMoreAddress ? 'hide' : ''"
                    >
                        <span
                            v-for="(item, key) in addressList"
                            :key="key"
                            :class="{ 'item-active': item.value == optionsData.areaCode }"
                            @click="tabClick(item,'areaCode')"
                            >{{ item.label }}</span
                        >
                    </div>
                </div>
                <div
                    class="more"
                    @click="showMoreAddress = !showMoreAddress"
                    v-if="addressList.length >7"
                >
                {{ showMoreAddress ? '收起' : '展开' }} <i class="el-icon-arrow-down"></i>
                </div>
            </div>
            <!-- 门店 -->
            <div v-if="showStore" class="list-item" >
                <span class="item-title">{{ storeTitle }}：</span>
                <div class="item-content">
                    <div
                        class="item-tab"
                        :class="showMoreStore ? 'hide' : ''"
                    >
                        <span
                            v-for="(item, key) in storeList"
                            :key="key"
                            :class="{ 'item-active': item.value == optionsData.store }"
                            @click="tabClick(item,'store')"
                            >{{ item.label }}</span
                        >
                    </div>
                </div>
                <div
                    class="more"
                    @click="showMoreStore = !showMoreStore"
                    v-if="storeList.length >7"
                >
                {{ showMoreStore ? '收起' : '展开' }} <i class="el-icon-arrow-down"></i>
                </div>
            </div>
            <!-- 设计级别 -->
            <div v-if="showDesLv" class="list-item" >
                <span class="item-title">{{ desLvTitle }}：</span>
                <div class="item-content">
                    <div
                        class="item-tab"
                        :class="showMoreDesLv ? 'hide' : ''"
                    >
                        <span
                            v-for="(item, key) in desLvList"
                            :key="key"
                            :class="{ 'item-active': item.value == optionsData.ranks }"
                            @click="tabClick(item,'ranks')"
                            >{{ item.label }}</span
                        >
                    </div>
                </div>
                <div
                    class="more"
                    @click="showMoreDesLv = !showMoreDesLv"
                    v-if="desLvList.length >6"
                >
                {{ showMoreDesLv ? '收起' : '展开' }} <i class="el-icon-arrow-down"></i>
                </div>
            </div>


            <!-- 户型-->
            <div v-if="showHouType" class="list-item" >
                <span class="item-title">{{ houTypeTitle }}：</span>
                <div class="item-content">
                    <div
                        class="item-tab"
                        :class="showMoreHosType ? 'hide' : ''"
                    >
                        <span
                            v-for="(item, key) in housetypeList"
                            :key="key"
                            :class="{ 'item-active': item.value == optionsData.houseType }"
                            @click="tabClick(item,'houseType')"
                            >{{ item.label }}</span
                        >
                    </div>
                </div>
                <div
                    class="more"
                    @click="showMoreHosType = !showMoreHosType"
                    v-if="housetypeList.length > 9"
                >
                {{ showMoreHosType ? '收起' : '展开' }} <i class="el-icon-arrow-down"></i>
                </div>
            </div>
           
            <!-- 面积-->
            <div v-if="showAreas" class="list-item" >
                <span class="item-title">{{ areasTitle }}：</span>
                <div class="item-content">
                    <div
                        class="item-tab"
                        :class="showMoreAreasType ? 'hide' : ''"
                    >
                        <span
                            v-for="(item, key) in houseAreasList"
                            :key="key"
                            :class="{ 'item-active': item.value == optionsData.houseArea }"
                            @click="tabClick(item,'houseArea')"
                            >{{ item.label }}</span
                        >
                    </div>
                </div>
                <div
                    class="more"
                    @click="showMoreAreasType = !showMoreAreasType"
                    v-if="houseAreasList.length > 8"
                >
                    {{ showMoreAreasType ? '收起' : '展开' }} <i class="el-icon-arrow-down"></i>
                </div>
            </div>
             <!-- 风格 -->
             <div v-if="showHouStyle" class="list-item" >
                <span class="item-title">{{  houStyleTitle }}：</span>
                <div class="item-content">
                    <div
                        class="item-tab"
                        :class="showMoreDesType ? 'hide' : ''"
                    >
                        <span
                            v-for="(item, key) in desStyleList"
                            :key="key"
                            :class="{ 'item-active': item.value == optionsData.designStyle }"
                            @click="tabClick(item,'designStyle')"
                            >{{ item.label }}</span
                        >
                    </div>
                </div>
                <div
                    class="more"
                    @click="showMoreDesType = !showMoreDesType"
                    v-if="desStyleList.length >9 "
                >
                {{ showMoreDesType ? '收起' : '展开' }} <i class="el-icon-arrow-down"></i>
                </div>
            </div>
        </div>
        <div class="box1 ">
            <div v-if="showDialog" class="mask" @click="showDialog=false"></div>
            <div class="tab-list1 f-x-a-c">
                <div 
                    v-if="showAddress"
                    @click="handleClick('areaCode')" 
                    class="list-item1 f-x-c-c"
                    :class="curTabType=='areaCode' && showDialog ? 'list-item-active':'' ">
                    <span class="item-text1">{{addressTitle}}</span>
                    <i  class="el-icon-caret-bottom icon"></i>
                </div>
                <div v-if="showAddress" class="cus-line"></div>
                <div 
                    v-if="showStore"
                    @click="handleClick('store')" 
                    class="list-item1 f-x-c-c"
                    :class="curTabType=='store' && showDialog ? 'list-item-active':'' ">
                    <span class="item-text1">{{storeTitle}}</span>
                    <i  class="el-icon-caret-bottom icon"></i>
                </div>
                <div v-if="showStore" class="cus-line"></div>
                <div 
                    v-if="showDesLv"
                    @click="handleClick('ranks')" 
                    class="list-item1 f-x-c-c"
                    :class="curTabType=='ranks' && showDialog ? 'list-item-active':'' ">
                    <span class="item-text1">{{desLvTitle}}</span>
                    <i class="el-icon-caret-bottom icon"></i>
                </div>
                <div v-if="showDesLv" class="cus-line"></div>
                <div 
                    v-if="showHouType"
                    @click="handleClick('houseType')" 
                    class="list-item1 f-x-c-c"
                    :class="curTabType=='houseType' && showDialog ? 'list-item-active':'' ">
                    <span class="item-text1">{{houTypeTitle}}</span>
                    <i class="el-icon-caret-bottom icon"></i>
                </div>
                <div v-if="showHouType" class="cus-line"></div>
                <div 
                    v-if="showAreas"
                    @click="handleClick('houseArea')" 
                    class="list-item1 f-x-c-c"
                    :class="curTabType=='houseArea' && showDialog ? 'list-item-active':'' ">
                    <span class="item-text1">{{areasTitle}}</span>
                    <i class="el-icon-caret-bottom icon"></i>
                </div>
                <div v-if="showAreas" class="cus-line"></div>
                <div 
                    v-if="showHouStyle"
                    @click="handleClick('designStyle')" 
                    class="list-item1 f-x-c-c"
                    :class="curTabType=='designStyle' && showDialog ? 'list-item-active':'' ">
                    <span class="item-text1">{{houStyleTitle}}</span>
                    <i class="el-icon-caret-bottom icon"></i>
                </div>
            </div>
            <transition name="el-fade-in">
                <div v-if="showDialog" class="dialog">
                    <div
                        v-for="(item,index) in curDialogList"
                        :key="index" 
                        @click="tabClick(item,curTabType)"
                        class="dialog-item1 f-x-c-c"
                        :class="{ 'dialog-item-active': item.value == optionsData[curTabType] }">
                        {{item.label}}
                    </div>
                </div>
            </transition>
            
        </div>
	</div>
</template>
<script>
import { useSalesRoomDict,useHouseAreasDict,useHousetypeDict,useDesStyleDict,useDesRankDict } from '@/utils/frequent.js'
import { codeToText } from 'element-china-area-data'
import {useCityDict} from '@/utils/frequent.js'
export default {
    props:{
        addressTitle:{
            default:'区域',
            type:String
        },
        storeTitle:{
            default:'所在门店',
            type:String
        },
        desLvTitle:{
            default:'设计职位',
            type:String
        },
        houTypeTitle:{
            default:'户型',
            type:String
        },
        areasTitle:{
            default:'面积',
            type:String
        },
        houStyleTitle:{
            default:'风格',
            type:String
        },
        showAddress:{
            default:true,
            type:Boolean
        },
        showStore:{
            default:true,
            type:Boolean
        },
        showDesLv:{
            default:true,
            type:Boolean
        },
        showHouType:{
            default:true,
            type:Boolean
        },
        showAreas:{
            default:true,
            type:Boolean
        },
        showHouStyle:{
            default:true,
            type:Boolean
        },
    },
	data() {
		return {
            // 是否展示更过按钮
            showMoreAddress:false,
            showMoreStore:false,
            showMoreHosType:false,
            showMoreDesType:false,
            showMoreAreasType:false,
            showMoreDesLv:false,

            // 选项列表数据
            addressList:[],
            storeList:[],
            housetypeList:[],
            desStyleList:[],
            houseAreasList:[],
            desLvList:[],

            // 所选的值
            optionsData:{
                // roomNo:'-1',
                houseType:'-1',
                designStyle:'-1',
                houseArea:'-1',
                ranks:'-1',
                store:'-1',
                areaCode:'-1'
            },
          
            curDialogList:[],
            curTabType:null,
            showDialog:false
		}
	},
	async mounted() {
        this.getAddressList()
        this.getDesLvList()
        this.getHousetypeList()
        this.getDesStyleList()
        this.getHouseAreasList()
	},
	methods: {
        async getAddressList(){
            try{
                const res = await useSalesRoomDict()
                const cityDict = await useCityDict()
                const list = [
                    {label:'全部',value:'-1'}
                ]
                const storeList = [
                    {label:'全部',value:'-1'}
                ]
                res.map(item=>{
                    if(item.showRoomNo && item.region){
                        storeList.push({
                            label:item.showRoomName,
                            value:item.showRoomNo
                        })
                    }
                })
                cityDict.map(item=>{
                    const obj = {}
                    if(item.cityCode=='310100'){
                        obj.value = item.cityCode
                        obj.label = '上海市'
                    }else{
                        obj.value = item.cityCode
                        obj.label = item.name
                    }
                    list.push(obj)
                })

                this.addressList = list
                this.storeList = storeList
            }catch(err){
                console.log(err);
            }
        },
        async getDesLvList(){
            try{
                const res = await useDesRankDict()
                console.log("REs",res);
                const list = [
                    {label:'全部',value:'-1'}
                ]
                res.map(item=>{
                    const obj = {
                        label:item.label,
                        value:item.value
                    }
                    list.push(obj)
                })
                this.desLvList = list
            }catch(err){
                console.log(err);
            }
        },
        async getHousetypeList(){
            try{
                const res = await useHousetypeDict()
                const list = [{label:'不限',value:'-1'}]
                res.map(item=>{
                    const obj = {
                        label:item.label,
                        value:item.value
                    }
                    list.push(obj)
                })
                this.housetypeList = list
            }catch(err){
                console.log(err);
            }
        },
        async getDesStyleList(){
            try{
                const res = await useDesStyleDict()
                const list = [{label:'不限',value:'-1'}]
                res.map(item=>{
                    const obj = {
                        label:item.label,
                        value:item.value
                    }
                    list.push(obj)
                })
                this.desStyleList = list
            }catch(err){
                console.log(err);
            }
        },
        async getHouseAreasList(){
            try{
                const res = await useHouseAreasDict()
                const list = [{label:'不限',value:'-1'}]
                res.map(item=>{
                    const obj = {
                        label:item.label,
                        value:item.value
                    }
                    list.push(obj)
                })
                this.houseAreasList = list
            }catch(err){
                console.log(err);
            }
        },
        handleClick(type){
            if(this.showDialog && type==this.curTabType) this.showDialog = false
            else {
                this.curTabType = type
                switch(type){
                    case 'areaCode':
                        this.curDialogList = this.addressList
                        break
                    // case 'roomNo':
                    //     this.curDialogList = this.storeList
                    //     break
                    case 'store':
                        this.curDialogList = this.storeList
                        break
                    case 'ranks':
                        this.curDialogList = this.desLvList
                        break
                    case 'houseType':
                        this.curDialogList = this.housetypeList
                        break
                    case 'designStyle':
                        this.curDialogList = this.desStyleList
                        break
                    case 'houseArea':
                        this.curDialogList = this.houseAreasList
                        break
                }
                this.showDialog = true
            }
        },
        tabClick(data,type){
            switch(type){
                case 'areaCode':
                    // this.optionsData.roomNo = data.value
                    this.optionsData.areaCode = data.value
                    break
                // case 'roomNo':
                case 'store':
                    // this.optionsData.roomNo = data.value
                    this.optionsData.store = data.value
                    break
                case 'ranks':
                    this.optionsData.ranks = data.value
                    break
                case 'houseType':
                    this.optionsData.houseType = data.value
                    break
                case 'designStyle':
                    this.optionsData.designStyle = data.value
                    break
                case 'houseArea':
                    this.optionsData.houseArea = data.value
                    break
            }
            this.$emit('callback',this.optionsData)
            console.log("this.optionsData",this.optionsData);
        }
    },
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .category {
        height: auto !important;
        width: 100%;
        background: #fff;
		.box {
            display: none;
		}
        .box1{
            width: 100%;
            position: relative;
            .mask{
                background: rgba(0,0,0,0.5);
                height: 100vh;
                width: 100%;
                position: fixed;
                top: 0;
                z-index: 1000;
            }
            .tab-list1{
                z-index: 1001;
                position: relative;
                height: 40px;
                width: 100%;
                // border-bottom: 1px solid #ccc;
                box-shadow: 0px 1px 5px 0px rgba(8,1,3,0.1);
                background: #fff;
                .cus-line:last-child{
                    display: none;
                }
                .list-item:last-child{
                    border-right:none !important;
                }
                .cus-line{
                    height: 25px;
                    width: 1px;
                    background: #ccc;
                    opacity: 0.6;
                }
                .list-item-active{
                    color:#753CBE;
                    .item-text1{
                        color:#753CBE !important;
                    }
                }
                .list-item1{
                    padding: 5px ;
                    height: 40px;
                    .item-text1{
                        
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;
                        line-height: 14px;
                    }
                    .icon{
                        font-size: 16px;
                    }
                }
            }
            .dialog{
                z-index: 1001;
                padding: 18px 12px;
                position: absolute;
                top: 40px;
                left: 0;
                min-height: 100px;
                width: 100%;
                box-sizing: border-box;
                background: #fff;
                display: grid;
                grid-template-columns: repeat(3,1fr);
                gap: 20px;
                box-shadow: 0px 7px 14px 0px rgba(0,0,0,0.2);
                .dialog-item1{
                    // width: 105px;
                    height: 35px;
                    background: #F7F7F7;
                    border-radius: 2px;
                    
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                }
                .dialog-item-active{
                    background: #753CBE;
                    color: #FFFFFF;
                }
            }
        }
       

		
	}
}
@media screen and (min-width: 900px) {
	.category {
		// box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        // margin-bottom: 15px;
        // min-height: 140px;
        // height: auto !important;
        // height: 140px;
        width: 100%;
        background: #fff;
        .box1{
            display: none;
        }
		.box {
			display: flex;
			margin: auto;
			height: 100%;
			flex-direction: column;
            width: 100%;
            box-sizing: border-box;
		}
        .list-item:last-child{
            border-bottom: none;
        }
		.list-item {
			display: flex;
            padding: 10px 22px 10px 22px;
            border-bottom: 1px solid #E5E5E5;
		}

		.list-item:last-of-type {
			margin-bottom: 0;
		}

		.item-title {
            
            font-weight: 400;
            font-size: 22px;
            color: #241934;
            line-height: 40px;
		}

		.item-content {
			display: flex;
			flex: 1;
		}

		.item-tab {
			flex: 1;
			margin-right: 15px;
			height: 40px;
			overflow: hidden;
		}

		.item-tab span {
			display: inline-block;
			margin: 0 40px 15px 0;
			cursor: pointer;
			padding: 0 15px;
           
            
            font-weight: 400;
            font-size: 24px;
            color: #241934;
            line-height: 40px;
            text-align: center;
            box-sizing: border-box;
            height: 40px;
		}

		.more {
			cursor: pointer;
            
            font-weight: 400;
            font-size: 24px;
            color: #736F7A;
            line-height: 40px;
            /deep/ .el-icon-arrow-down{
                font-weight: 600 !important;
                font-size: 24px !important;
            }
		}

		.item-active {
			color: white !important;
            background: #753CBE;
            border-radius: 4px;
		}

		.item-tab span:hover {
			background-color: #753CBE;
			color: white;
			border-radius: 4px;
		}

		.hide {
			min-height: 35px;
			height: auto !important;
		}
	}
}
</style>
